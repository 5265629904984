import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import ProtectedRoute from './containers/DefaultLayout/ProtectedRoute'
import { AuthProvider } from './containers/DefaultLayout/AuthContext'

// import { renderRoutes } from 'react-router-config';
import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <AuthProvider>
              <Switch>
                <Route path="/login" render={props => <Login {...props}/>} />
                <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </AuthProvider>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
