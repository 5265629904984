import React from 'react'

const AuthContext = React.createContext()

class AuthProvider extends React.Component {
    state = {
        isAuth: false
    }

    constructor() {
        super()
        this.state = {
            isAuth: localStorage.getItem('isAuth') || false,
            error : null
        }

        this.login = this.login.bind(this)
        this.logout = this.logout.bind(this)
    }

    login(username, password) {

        fetch('https://8mecwse58h.execute-api.us-east-1.amazonaws.com/dev/api/login', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "username": username || "",
                "password": password || ""
            })
        }).then((resp) => {
            return resp.json()
        }).then((json) => {
            if (json.status == "ERR"){
                this.setState({
                    error: json.errors
                })
            }
            else {
                localStorage.setItem('isAuth', true);
                this.setState({isAuth: true})
            }
        })
    }

    logout() {
        localStorage.removeItem('isAuth');
        this.setState({isAuth: false})
    }

    render() {
        return (
            <AuthContext.Provider value={{
                isAuth: this.state.isAuth,
                login: this.login,
                logout: this.logout,
                error: this.state.error
            }}>
            {this.props.children}
            </AuthContext.Provider>
        )
    }
}

const AuthConsumer = AuthContext.Consumer

export {
    AuthProvider,
    AuthConsumer
}
